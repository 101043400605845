/******* Customized Bootstrap ********/

$primary: #C45508;
$light: #FFFFFF;
$dark: #FFDB58;

$font-family-base: 'Heebo', sans-serif;

$headings-font-family: 'Montserrat', sans-serif;

$body-bg: $light;

$body-color: #000000;

$headings-color: $dark;

$headings-font-weight: 700;

$display-font-weight: 700;

$enable-responsive-font-sizes: true;

$border-radius: 2px;

$link-decoration: none;

$enable-negative-margins: true;

@import "bootstrap/scss/bootstrap";
